<template>
	<div>
		<a-spin :spinning="loading" v-if="!showTemp">
			<Header :title="'用户资产详情'" @back="onBack(false)"></Header>
			<div class="userInfo">
				<h4
					style="margin-top: 20px;padding-left: 20px;border-left: solid 4px #169bd5;margin-bottom: 10px;font-size: 20px;">
					用户信息</h4>
				<a-descriptions style="margin-top: 30px" :column="4">
					<a-descriptions-item label="用户名称">{{ userInfo.nickname || '-' }}</a-descriptions-item>
					<a-descriptions-item label="手机号">{{ userInfo.phone ? userInfo.phone : "--" }}</a-descriptions-item>
					<a-descriptions-item label="性别">
						<div v-if="userInfo.sex === 1">男</div>
						<div v-if="userInfo.sex === 2">女</div>
						<div v-if="userInfo.sex === 3">未知</div>
					</a-descriptions-item>
					<a-descriptions-item label="生日">{{ transDateTime(userInfo.birthday) }}</a-descriptions-item>
					<a-descriptions-item label="用户ID">{{ userInfo.id }}</a-descriptions-item>
					<a-descriptions-item label="积分">
						<div style="margin-top: -5px;">
							<span>{{ userInfo.point }}</span>
							<!-- todo -->
							<a-button v-permission="['user_manage_list_point']" type="link" @click="onScore">变更积分数量</a-button>
						</div>
					</a-descriptions-item>
					<a-descriptions-item label="特色会员">
						<div>
							<span>{{ ['未开通', '已开通', '赠送中'][userInfo.specialMemberStatus] }}</span>
							<!-- <a-button type="link" @click="onRenew">设置到期时间</a-button> -->
							<div>过期时间：{{ transDateTime(userInfo.specialMemberExpireTime) }}</div>
						</div>
					</a-descriptions-item>
				</a-descriptions>
			</div>
			<div class="propertyInfo">
				<h4
					style="margin-top: 20px;padding-left: 20px;border-left: solid 4px #169bd5;margin-bottom: 10px;font-size: 20px;">
					资产信息</h4>
				<a-tabs @change="onChange" v-model:activeKey="activeKey" size="small" type="card">
					<a-tab-pane key="5" tab="购票记录">
						<a-table :pagination="pagination" :dataSource="buyList" :columns="buyColumns" rowKey="id"
							size="middle" :scroll="{ x: 4000 }">
							<template #bodyCell="{ column, record }">
								<template v-if="column.key === 'createTime'">
									{{ transDateTime(record.createTime, 1) }}
								</template>
								<template v-if="column.key === 'showStartTime'">
									{{ transDateTime(record.showStartTime, 1) }}
								</template>
								<template v-if="column.key === 'seatNum'">
									{{ record.orderItemList?.length }}
								</template>
								<template v-if="column.key === 'seatName'">
									<div v-for="item in record.orderItemList" :key="item.id">
										{{ item.seatInfoDetail.rowCode }}排
										{{ item.seatInfoDetail.columnCode }}座
										<span v-if="item.seatInfoDetail.partitionName"> ---
											{{ item.seatInfoDetail.partitionName }}</span>
									</div>
								</template>
								<template v-if="column.key === 'dataSourceServe'">
									<div>增值服务费：{{ }}</div>
									<div>平台服务费：{{ }}</div>
								</template>
								<template v-if="column.key === 'couponType'">
									<div v-if="record.couponType === 1">代金券</div>
									<div v-if="record.couponType === 2">抵扣券</div>
								</template>
								<template v-if="column.key === 'printTime'">
									{{ transDateTime(record.printTime, 1) }}
								</template>
								<template v-if="column.key === 'dataSource'">
									<div v-if="record.dataSource === 1">凤凰佳影</div>
									<div v-if="record.dataSource === 2">凤凰云智</div>
									<div v-if="record.dataSource === 3">辰星云3.0</div>
									<div v-if="record.dataSource === 4">辰星云4.0</div>
								</template>
							</template>
						</a-table>
					</a-tab-pane>
					<a-tab-pane key="6" tab="卖品记录">
						<a-table :pagination="pagination" :dataSource="salesList" :columns="salesColumns" rowKey="id"
							size="middle" :scroll="{ x: 3000 }">
							<template #bodyCell="{ column, record }">
								<template v-if="column.key === 'status'">
									<div v-if="record.status === 'SYSTEM_CANCEL'">系统超时取消</div>
									<div v-if="record.status === 'CUSTOMER_CANCEL'">用户自行取消</div>
									<div v-if="record.status === 'WAIT_BUYER_PAY'">等待买家付款</div>
									<div v-if="record.status === 'WAIT_GROUP'">等待成团</div>
									<div v-if="record.status === 'WAIT_SELLER_SEND_GOODS'">等待卖家发货</div>
									<div v-if="record.status === 'WAIT_CHECK'">等待核销</div>
									<div v-if="record.status === 'WAIT_BUYER_CONFIRM_GOODS'">等待买家确认收货</div>
									<div v-if="record.status === 'EXPIRED'">过期</div>
									<div v-if="record.status === 'WAIT_COMMENT'">待评价</div>
									<div v-if="record.status === 'TRADE_FINISH'">订单完成</div>
									<div v-if="record.status === 'AFTERSALE_FINISH'">售后完成</div>
								</template>
								<template v-if="column.key === 'payTime'">
									{{ transDateTime(record.payTime) }}
								</template>
								<template v-if="column.key === 'orderItemList-goodsNo'">
									<div v-for="item in record.orderItemList" :key="item.id">
										{{ item.goodsNo ? item.goodsNo : '-' }}
									</div>
								</template>
								<template v-if="column.key === 'orderItemList-goodsTitle'">
									<div v-for="item in record.orderItemList" :key="item.id">
										{{ item.goodsTitle ? item.goodsTitle : '-' }}
									</div>
								</template>
								<template v-if="column.key === 'orderItemList-skuName'">
									<div v-for="item in record.orderItemList" :key="item.id">
										{{ item.skuName ? item.skuName : '-' }}
									</div>
								</template>
								<template v-if="column.key === 'orderItemList-price'">
									<div v-for="item in record.orderItemList" :key="item.id">
										{{ item.price ? item.price : '-' }}
									</div>
								</template>
								<template v-if="column.key === 'orderItemList-num'">
									<div v-for="item in record.orderItemList" :key="item.id">
										{{ item.num ? item.num : '-' }}
									</div>
								</template>
								<template v-if="column.key === 'payableAmount'">
									{{ record.payment - record.couponDeduction }}
								</template>
								<template v-if="column.key === 'couponName'">
									<div v-if="record.orderCouponList && record.orderCouponList.length">
										<div v-for="(item, index) in record.orderCouponList" :key="item">
											{{ item.couponName || '' }}<span v-if="index < record.orderCouponList.length - 1">、</span>
										</div>
									</div>
									<div v-else>-</div>
								</template>
								<template v-if="column.key === 'payMessageType'">
									<div v-if="record.payMessageType === 'wx_lite'">微信支付</div>
									<div v-if="record.payMessageType === 'card'">一卡通支付</div>
									<div v-if="record.payMessageType === 'alipay'">支付宝支付</div>
								</template>
								<template v-if="column.key === 'orderVerifier-expiredEndTime'">
									{{ transDateTime(record.orderVerifier?.expiredEndTime) }}
								</template>
								<template v-if="column.key === 'refundTime'">
									{{ transDateTime(record.refundTime) }}
								</template>
							</template>
						</a-table>
					</a-tab-pane>
					<a-tab-pane key="7" tab="商品记录">
						<a-table :pagination="pagination" :dataSource="goodsList" :columns="goodsColumns" rowKey="id"
							size="middle" :scroll="{ x: 3500 }">
							<template #bodyCell="{ column, record }">
								<template v-if="column.key === 'payTime'">
									{{ transDateTime(record.payTime) }}
								</template>
								<template v-if="column.key === 'orderItemList-goodsTitle'">
									<div v-for="item in record.orderItemList" :key="item.id">
										{{ item.goodsTitle ? item.goodsTitle : '-' }}
									</div>
								</template>
								<template v-if="column.key === 'orderItemList-skuName'">
									<div v-for="item in record.orderItemList" :key="item.id">
										{{ item.skuName ? item.skuName : '-' }}
									</div>
								</template>
								<template v-if="column.key === 'orderItemList-price'">
									<div v-for="item in record.orderItemList" :key="item.id">
										{{ item.price || '-' }}
									</div>
								</template>
								<template v-if="column.key === 'orderItemList-num'">
									<div v-for="item in record.orderItemList" :key="item.id">
										{{ item.num || '-' }}
									</div>
								</template>
								<template v-if="column.key === 'payable'">
									{{ record.payment + record.couponDeduction }}
								</template>
								<template v-if="column.key === 'deliveryType'">
									<div v-if="record.deliveryType === 1">快递配送</div>
									<div v-if="record.deliveryType === 2">到店核销</div>
									<div v-if="record.deliveryType === 3">线上发货</div>
								</template>
								<template v-if="column.key === 'orderVerifier-storeName'">
									{{ record.orderVerifier?.storeName ? record.orderVerifier?.storeName : '-' }}
								</template>
								<template v-if="column.key === 'orderAddress-info'">
									<div v-if="record.deliveryType === 1">
										<p>收货人：{{ record.orderAddress?.name ? record.orderAddress?.name : '-' }}</p>
										<p>联系电话：{{ record.orderAddress?.phone ? record.orderAddress?.phone : '-' }}</p>
										<p>收货地址：{{ record.orderAddress?.address ? record.orderAddress?.address : '-' }}
										</p>
									</div>
									<div v-if="record.deliveryType === 2">
										-
									</div>
								</template>
								<template v-if="column.key === 'payMessageType'">
									<div v-if="record.payMessageType === 'wx_lite'">微信支付</div>
									<div v-if="record.payMessageType === 'alipay'">支付宝支付</div>
									<div v-if="record.payMessageType === 'card'">一卡通支付</div>
									<div v-if="record.payMessageType === 'zero'">零元支付</div>
								</template>
								<template v-if="column.key === 'status'">
									<div v-if="record.status === 'SYSTEM_CANCEL'">系统超时取消</div>
									<div v-if="record.status === 'CUSTOMER_CANCEL'">用户自行取消</div>
									<div v-if="record.status === 'WAIT_BUYER_PAY'">等待买家付款</div>
									<div v-if="record.status === 'WAIT_GROUP'">等待成团</div>
									<div v-if="record.status === 'WAIT_SELLER_SEND_GOODS'">等待卖家发货</div>
									<div v-if="record.status === 'WAIT_CHECK'">等待核销</div>
									<div v-if="record.status === 'WAIT_BUYER_CONFIRM_GOODS'">等待买家确认收货</div>
									<div v-if="record.status === 'EXPIRED'">过期</div>
									<div v-if="record.status === 'WAIT_COMMENT'">待评价</div>
									<div v-if="record.status === 'TRADE_FINISH'">订单完成</div>
									<div v-if="record.status === 'AFTERSALE_FINISH'">售后完成</div>
								</template>
								<template v-if="column.key === 'consignTime'">
									{{ transDateTime(record.consignTime) }}
								</template>
								<template v-if="column.key === 'signTime'">
									{{ transDateTime(record.signTime) }}
								</template>
								<template v-if="column.key === 'discountType'">
									{{ ['-', '优惠券', '特色会员'][record.discountType] }}
								</template>
							</template>
						</a-table>
					</a-tab-pane>
					<a-tab-pane key="1" tab="一卡通">
						<a-table :pagination="pagination" :dataSource="list" :columns="cardColumns" rowKey="id"
							size="middle" :scroll="{ x: 1300 }">
							<template #bodyCell="{ column, record }">
								<template v-if="column.key === 'cardType'">
									{{ ['普通卡', '主题卡', '礼品卡'][record.cardType - 1] }}
								</template>
								<template v-if="column.key === 'fromType'">
									{{ ['购买', '转赠', '后台发放', '兑换'][record.fromType - 1] }}
								</template>
								<template v-if="column.key === 'createTime'">
									{{ transDateTime(record.createTime) }}
								</template>
								<template v-if="column.key === 'status'">
									{{ ['待激活', '正常', '转赠中', '已赠送'][record.status] }}
								</template>
								<template v-if="column.key === 'isDisabled'">
									{{ record.isDisabled ? '已禁用' : '已启用' }}
								</template>
								<template v-if="column.key === 'operate'">
									<a-dropdown>
										<a-button @click.prevent>操作
											<Icon icon="DownOutlined"></Icon>
										</a-button>
										<template #overlay>
											<a-menu>
												<div v-if="record.isDisabled" @click="onCardDisabled(record)">
													<a-menu-item>
														启用
													</a-menu-item>
												</div>
												<div v-else @click="onCardDisabled(record)">
													<a-menu-item>
														禁用
													</a-menu-item>
												</div>
												<div @click="onRecharge(record, '充值')">
													<a-menu-item>
														充值
													</a-menu-item>
												</div>
												<div @click="toRecord(record)">
													<a-menu-item>
														充值记录
													</a-menu-item>
												</div>
											</a-menu>
										</template>
									</a-dropdown>
								</template>
							</template>
						</a-table>
					</a-tab-pane>
					<a-tab-pane key="2" tab="优惠券">
						<a-table :pagination="pagination" :dataSource="list" :columns="couponColumns" rowKey="id"
							size="middle" :scroll="{ x: 1300 }">
							<template #bodyCell="{ column, record }">
								<template v-if="column.key === 'type'">
									<div>{{ record.type == 1 ? '代金券' :'兑换券' }}</div>
								</template>
								<template v-if="column.key === 'useLimit'">
									{{ ['影片', '演出', '卖品', '商城'][record.useLimit - 1] }}
								</template>
								<template v-if="column.key === 'time'">
									<div>
										<div>{{ transDateTime(record.startTime, 1) }}</div>
										至
										<div>{{ transDateTime(record.endTime, 1) }}</div>
									</div>
								</template>
								<template v-if="column.key === 'fromType'">
									{{ ['领取', '购买券包', '转赠获得', '后台发放'][record.fromType - 1] }}
								</template>
								<template v-if="column.key === 'validPeriodType'">
									<div v-if="record.validPeriodType === 1">天数</div>
									<div v-if="record.validPeriodType === 2">具体日期</div>
								</template>
								<template v-if="column.key === 'period'">
									<div>
										<div>
											<div>{{ transDateTime(record.startTime, 1) }}</div>
											至
											<div>{{ transDateTime(record.endTime, 1) }}</div>
										</div>
									</div>
								</template>
								<template v-if="column.key === 'isDisabled'">
									<div>{{ record.isDisabled ? '已禁用' : '已启用' }}</div>
								</template>
								<template v-if="column.key === 'operate'">
									<a-dropdown>
										<a-button @click.prevent>操作
											<Icon icon="DownOutlined"></Icon>
										</a-button>
										<template #overlay>
											<a-menu>
												<div v-if="record.isDisabled" @click="onCouponDisabled(record)">
													<a-menu-item>
														启用
													</a-menu-item>
												</div>
												<div v-else @click="onCouponDisabled(record)">
													<a-menu-item>
														禁用
													</a-menu-item>
												</div>
											</a-menu>
										</template>
									</a-dropdown>
								</template>
							</template>
						</a-table>
					</a-tab-pane>
					<a-tab-pane key="3" tab="积分变更记录">
						<a-table :pagination="pagination" :dataSource="list" :columns="changeColumns" rowKey="id"
							size="middle">
							<template #bodyCell="{ column, record }">
								<template v-if="column.key === 'createTime'">
									{{ transDateTime(record.createTime) }}
								</template>
								<template v-if="column.key === 'type'">
									{{ ['收入', '消费'][record.type - 1] }}
								</template>
								<template v-if="column.key === 'businessType'">
									{{ ['订单赠送', '订单消费', '订单退回', '订单售后', '签到'][record.businessType - 1] }}
								</template>
								<template v-if="column.key === 'operate'">
									<a-dropdown>
										<a-button @click.prevent>操作
											<Icon icon="DownOutlined"></Icon>
										</a-button>
										<template #overlay>
											<a-menu>
												<div>
													<a-menu-item>
														撤销变更
													</a-menu-item>
												</div>
											</a-menu>
										</template>
									</a-dropdown>
								</template>
							</template>
						</a-table>
					</a-tab-pane>
					<!-- <a-tab-pane key="4" tab="积分时间">
						<a-table :pagination="pagination" :dataSource="list" :columns="integralColumns" rowKey="id"
							size="middle">
							<template #bodyCell="{ column, record }">
								<template v-if="column.key === 'isDisabled'">
									{{ transDateTime(record.isDisabled) }}
								</template>
							</template>
						</a-table>
					</a-tab-pane> -->
				</a-tabs>
			</div>

			<!--  续费特色会员  -->
			<a-modal title="特色会员时长" v-model:visible="isShowRenew" @ok="onRenewOk" style="width: 500px">
				<a-spin :spinning="loading">
					<a-form style="margin-top: 30px;" ref="form" :model="renewModelRef" scrollToFirstError name="form"
						:labelCol="{span: 8, xxl: 5}" :wrapperCol="{span: 12, xxl: 13 }">
						<a-form-item name="dueTime" label="到期时间">
							{{ transDateTime(userInfo.specialMemberExpireTime) }}
						</a-form-item>
						<a-form-item label="续费时长" name="renewTime" extra="提示：设置到期时间为今天以前相当于直接抹去用户特色会员身份">
							<a-date-picker style="width: 220px;"
								v-model:value="renewModelRef.renewTime"></a-date-picker>
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>

			<!--  设置积分数量  -->
			<a-modal title="积分变更" v-model:visible="isShowScore" @ok="onScoreOk" @cancel="onScoreCancel" style="width: 650px">
				<a-spin :spinning="loading">
					<a-form style="margin-top: 30px;" ref="form" :model="integralModelRef" scrollToFirstError
						name="form" :labelCol="{span: 10, xxl: 5}" :wrapperCol="{span: 14, xxl: 13 }">
						<a-form-item name="score" label="现积分数量">
							{{ userInfo.point }}
						</a-form-item>
						<a-form-item label="积分变动数量" name="setScore" extra="注：正数增加积分,负数扣减积分">
							<a-input-number :precision="0" v-model:value="integralModelRef.changePoint"
								style="width: 150px;"></a-input-number>
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>

			<!--  充值  -->
			<a-modal :title="title" v-model:visible="isShowRecharge" @ok="onRechargeOk" style="width: 500px">
				<a-spin :spinning="loading">
					<a-form style="margin-top: 30px;" ref="rechargeFormRef" :model="rechargeModelRef" scrollToFirstError
						name="form" :labelCol="{span: 8, xxl: 5}" :wrapperCol="{span: 16, xxl: 13 }">
						<a-form-item name="money" label="余额">
							{{ rechargeModelRef.balance }}
						</a-form-item>
						<a-form-item label="充值金额" name="chargePrice" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-input-number :min="0" :precision="2" v-model:value="rechargeModelRef.chargePrice"
								style="width: 150px;"></a-input-number> 元
						</a-form-item>
						<a-form-item name="rechargedMoney" label="充值后金额">
							{{ rechargeModelRef.balance + (rechargeModelRef.chargePrice || 0) }}
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>
		<temp v-else :id="cardId" @back="onTempBack(false)"></temp>
	</div>
</template>

<script>
	import Header from "@/components/header/header.vue";
	import temp from "@/views/coupon/preferential/bindInfo/temp.vue"
	import {
		Icon
	} from "@/components/icon/icon.js";
	import {
		getMemberDetail,
		getUserPointRecord,
		changeUserPoint
	} from "@/service/modules/member.js";
	import {
		getOrderBaseList
	} from "@/service/modules/mall";
	import {
		getUserCouponList,
		disabledUserCoupon
	} from '@/service/modules/coupon.js';
	import {
		getCinemaOrderList,
		getOrderBaseSnackList,
		getUserUnionCardList,
		chargeUserUnionCard
	} from "@/service/modules/report.js";

	export default {
		components: {
			Icon,
			Header,
			temp,
		},
		props: {
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				cardId: 0,
				title: '',
				showTemp: false,
				rechargeModelRef: {},
				integralModelRef: {},
				renewModelRef: {},
				formState: {},
				modelRef: {},
				isShowRenew: false,
				isShowScore: false,
				isShowRecharge: false,
				isShowSelectCard: false,
				loading: false,
				userInfo: {
					name: "用户名称",
					phone: "17772290001",
					sex: "男",
					birthday: "2023-05-05"
				},
				activeKey: "5",
				list: [],
				cardList: [],
				cardColumns: [{
					title: "一卡通名称",
					dataIndex: "title"
				}, {
					title: "卡类型",
					key: "cardType"
				}, {
					title: "一卡通卡号",
					dataIndex: "cardNo"
				}, {
					title: "卡余额",
					dataIndex: "balance"
				}, {
					title: "获得方式",
					key: "fromType"
				}, {
					title: "获得时间",
					key: "createTime"
				}, {
					title: '卡状态',
					key: 'status'
				}, {
					title: "状态",
					key: "isDisabled"
				}, {
					title: "操作",
					key: "operate",
					fixed: 'right',
					width: 100
				}],
				couponList: [{
					id: 1
				}],
				couponColumns: [{
						title: "券名称",
						dataIndex: "title"
					}, {
						title: "券类型",
						key: "type"
					}, {
						title: "使用范围",
						key: "useLimit"
					}, {
						title: "满额可使用",
						dataIndex: "limitPrice"
					}, {
						title: "抵扣金额",
						dataIndex: "deductionPrice"
					}, {
						title: "补差金额",
						dataIndex: "diffPrice"
					}, {
						title: '有效期',
						key: 'period'
					},
					// {
					// 	title: '券来源',
					// 	key: 'fromType'
					// }, 
					{
						title: "状态",
						key: "isDisabled"
					}, {
						title: "操作",
						key: "operate",
						fixed: 'right',
						width: 100
					}
				],
				changeColumns: [{
					title: "变更时间",
					key: "createTime"
				}, {
					title: '变更类型',
					key: 'type'
				}, {
					title: '变更来源',
					key: 'businessType'
				}, {
					title: "变更数量",
					dataIndex: "lessPoint"
				}, {
					title: "变更前积分",
					dataIndex: "beforePoint"
				}, {
					title: "变更后积分",
					dataIndex: "afterPoint"
				}, 
				// { // todo 待接口补充逻辑
				// 	title: "操作人",
				// 	dataIndex: "" // todo
				// }, {
				// 	title: "是否撤销",
				// 	key: "" // todo
				// }, {
				// 	title: "撤销时间",
				// 	key: "" // todo
				// }, {
				// 	title: "撤销人",
				// 	dataIndex: "" // todo
				// }, {
				// 	title: "操作",
				// 	key: "operate"
				// },
				],
				integralList: [{
					id: 1
				}],
				integralColumns: [{
					title: "获得时间",
					key: "" // 已废弃todo
				}, {
					title: "获得积分",
					dataIndex: "" // 已废弃todo
				}, {
					title: "获得前积分",
					dataIndex: "" // 已废弃todo
				}, {
					title: "获得后积分",
					dataIndex: ""  // 已废弃todo
				}, {
					title: "获得来源",
					key: ""  // 已废弃todo
				}, {
					title: "操作人",
					dataIndex: ""  // 已废弃todo
				}, {
					title: "过期时间",
					key: ""  // 已废弃todo
				}],
				buyList: [],
				buyColumns: [{
					title: '订单编号',
					dataIndex: 'orderNo',
				}, {
					title: '购买时间',
					key: 'createTime',
				}, {
					title: '影片编码',
					dataIndex: 'filmCode',
				}, {
					title: '影片名称',
					dataIndex: 'filmName',
				}, {
					title: '放映时间',
					key: 'showStartTime',
				}, {
					title: '影院名称',
					dataIndex: 'cinemaName',
				}, {
					title: '影厅名称',
					dataIndex: 'hallName',
				}, {
					title: '座位数量',
					key: 'seatNum',
				}, {
					title: '座位',
					key: 'seatName',
				}, 
				// {
				// 	title: '售票系统服务费',
				// 	key: 'dataSourceServe', // todo
				// }, 
				{
					title: '网络代售费',
					dataIndex: 'serviceCharge',
				}, {
					title: '应付金额',
					dataIndex: 'totalFee',
				}, {
					title: '实付金额',
					dataIndex: 'payment',
				}, {
					title: '优惠券',
					dataIndex: 'couponName'
					// key: 'couponType',
				}, {
					title: '优惠券优惠',
					dataIndex: 'couponDeduction',
				}, {
					title: '一卡通优惠',
					dataIndex: 'cardDiscountDeduction',
				}, {
					title: '取票时间',
					key: 'printTime',
				}, {
					title: '退款服务费',
					dataIndex: 'afterSaleTotalServiceFee',
				}, {
					title: '原订单',
					dataIndex: 'changeOrderNo',
				}, {
					title: '出票渠道',
					key: 'dataSource',
				}],
				salesList: [],
				salesColumns: [{
					title: '订单编号',
					dataIndex: 'orderNo',
				}, {
					title: '订单状态',
					key: 'status',
				}, {
					title: '购买时间',
					key: 'payTime',
				}, {
					title: '所属影院',
					dataIndex: 'cinemaName',
				}, 
				// {
				// 	title: '卖品编码',
				// 	dataIndex: 'orderItemList-goodsNo',
				// }, 
				// {
				// 	title: '卖品类型',
				// 	key: '',
				// }, 
				{
					title: '卖品名称',
					key: 'orderItemList-goodsTitle',
				}, {
					title: '规格',
					key: 'orderItemList-skuName',
				}, {
					title: '单价',
					key: 'orderItemList-price',
					width: 100
				}, {
					title: '数量',
					key: 'orderItemList-num',
					width: 100
				}, {
					title: '应付金额',
					key: 'payableAmount',
					width: 100
				}, {
					title: '优惠券名称',
					key: 'couponName',
				}, {
					title: '优惠金额',
					dataIndex: 'couponDeduction',
					width: 100
				}, {
					title: '实付金额',
					dataIndex: 'payment',
					width: 100
				}, {
					title: '支付方式',
					key: 'payMessageType',
				}, {
					title: '核销时间',
					key: 'orderVerifier-expiredEndTime',
				}, {
					title: '退款时间',
					key: 'refundTime', 
				}],
				goodsList: [],
				goodsColumns: [{
					title: '订单号',
					dataIndex: 'orderNo',
				}, {
					title: '订单状态',
					key: 'status',
				}, {
					title: '购买时间',
					key: 'payTime',
				}, {
					title: '商品信息',
					key: 'orderItemList-goodsTitle',
				}, {
					title: '规格',
					key: 'orderItemList-skuName',
				}, {
					title: '单价',
					key: 'orderItemList-price',
					width: 100
				}, {
					title: '数量',
					key: 'orderItemList-num',
					width: 100
				}, {
					title: '应付金额',
					key: 'payable',
					width: 100
				}, {
					title: '物流方式',
					key: 'deliveryType',
				}, {
					title: '所属影院',
					key: 'orderVerifier-storeName',
				}, {
					title: '收货信息',
					key: 'orderAddress-info',
				}, {
					title: '运费',
					dataIndex: 'deliveryFee',
					width: 100
				}, {
					title: '优惠方式', 
					key: 'discountType',
				}, {
					title: '优惠金额',
					dataIndex: 'couponDeduction',
					width: 100
				}, {
					title: '实付金额',
					dataIndex: 'payment',
					width: 100
				}, {
					title: '支付类型',
					key: 'payMessageType',
				}, {
					title: '买家留言',
					dataIndex: 'remark',
				}, 
				// {
				// 	title: '核销时间',
				// 	key: '',  // todo
				// }, 
				{
					title: '发货时间',
					key: 'consignTime',
				}, {
					title: '收货时间',
					key: 'signTime',
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.cardPagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.cardPagination.current = 1;
						this.cardPagination.pageSize = pageSize;
						this.getData();
					}
				},
			};
		},
		created() {
			this.getData();
			this.getBuyList();
		},
		methods: {
			onBack(isRef) {
				this.$emit("back", isRef);
			},
			onTempBack(isRef) {
				this.showTemp = false;
				this.$nextTick(() => {
					this.getData();
				})
			},
			async getData() {
				this.getDetail();
				if (this.activeKey === '1') this.getCardList();
				if (this.activeKey === '2') this.getCouponList();
				if (this.activeKey === '3') this.getPointList();
				if (this.activeKey === '5') this.getBuyList();
				if (this.activeKey === '6') this.getSalseList();
				if (this.activeKey === '7') this.getGoodsList();
			},
			async getDetail() {
				this.loading = true;
				try {
					let ret = await getMemberDetail({
						id: this.id
					})
					this.loading = false;
					if (ret.code === 200) {
						this.userInfo = ret.data;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onRenewOk() {
				console.log("编辑完成");
			},
			onRenew() {
				this.isShowRenew = true;
			},
			onScoreOk() {
				this.$refs.form.validate().then(()=> {
					this.$confirm({
						title: '提示',
						content: '确定变更用户积分吗？',
						onOk: async ()=>{
							let postData = JSON.parse(JSON.stringify(this.integralModelRef));
							postData.userId = this.id;
							this.loading = true;
							let ret = await changeUserPoint(postData);
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.integralModelRef = {};
								this.isShowScore = false;
								this.getData();
							}
						}
					})
				})
			},
			onScoreCancel() {
				this.$refs.form.resetFields();
				this.isShowScore = false;
				this.integralModelRef = {};
			},
			onScore() {
				this.integralModelRef = {};
				this.isShowScore = true;
			},
			onRechargeOk() {
				this.$refs.rechargeFormRef.validate().then(()=> {
					this.$confirm({
						title: '提示',
						content: '确定充值吗？',
						onOk: async () => {
							this.loading = true;
							try {
								let ret = await chargeUserUnionCard({
									id: this.rechargeModelRef.id,
									chargePrice: this.rechargeModelRef.chargePrice
								})
								this.loading = false;
								if (ret.code === 200) {
									this.$message.success('操作成功');
									this.getData();
									this.isShowRecharge = false;
								}
							} catch (e) {
								this.loading = false;
							}
						}
					})
				})
			},
			onRecharge(item, title) {
				this.title = title;
				this.rechargeModelRef = {
					id: item.id,
					balance: item.balance
				};
				this.isShowRecharge = true;
			},
			toRecord(item) {
				this.showTemp = true;
				this.cardId = item.id;
			},
			onCardDisabled(item) {

			},
			onCouponDisabled(item) {
				this.$confirm({
					title: '提示',
					content: `确定${ item.isDisabled ? '启用' : '禁用' }该优惠券吗？`,
					onOk: async () => {
						this.loading = true;
						let ret = await disabledUserCoupon({
							id: item.id
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							this.getData();
						}
					}
				})
			},
			async getBuyList() {
				this.loading = true;
				try {
					let ret = await getCinemaOrderList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						userId: this.id,
					})
					this.loading = false;
					if (ret.code === 200) {
						this.buyList = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getSalseList() {
				this.loading = true;
				try {
					let ret = await getOrderBaseSnackList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						userId: this.id,
					})
					this.loading = false;
					if (ret.code === 200) {
						this.salesList = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getGoodsList() {
				this.loading = true;
				try {
					let ret = await getOrderBaseList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						userId: this.id,
					})
					this.loading = false;
					if (ret.code === 200) {
						this.goodsList = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getCardList() {
				this.loading = true;
				let ret = await getUserUnionCardList({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					userId: this.id
				})
				this.loading = false;
				if (ret.code === 200) {
					this.list = ret.data.list;
					this.pagination.total = ret.data.totalCount;
				}
			},
			async getCouponList() {
				// 优惠券列表
				this.loading = true;
				let ret = await getUserCouponList({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					userId: this.id
				})
				this.loading = false;
				if (ret.code === 200) {
					this.list = ret.data.list;
					this.pagination.total = ret.data.totalCount;
				}
			},
			async getPointList() {
				this.loading = true;
				let ret = await getUserPointRecord({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					userId: this.id
				})
				this.loading = false;
				if (ret.code === 200) {
					this.list = ret.data.list;
					this.pagination.total = ret.data.totalCount;
				}
			},
			onChange() {
				this.list = [];
				this.pagination.current = 1;
				this.pagination.pageSize = 10;
				if (this.activeKey === '1') this.getCardList();
				if (this.activeKey === '2') this.getCouponList();
				if (this.activeKey === '3') this.getPointList();
				if (this.activeKey === '5') this.getBuyList();
				if (this.activeKey === '6') this.getSalseList();
				if (this.activeKey === '7') this.getGoodsList();
			},
		}
	};
</script>

<style lang="less" scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>